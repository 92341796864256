// src/components/Footer.js

import React from "react";
import "./Footer.css";
import exampleImage from "../assets/FullLogo.png";

const Footer = () => {
  return (
    <footer className="footer section__padding">
      <div className="footer-container">
        <div>
          <h4 style={{ fontWeight: "bold" }}>Contact Us</h4>
          <p>Email: balasarawatch@gmail.com</p>
          <h4 style={{ fontWeight: "bold" }}>Address </h4>
          <p>Plot number: 58, Sector 1A</p>
          <p>Gandhidham, Kachchh 270201</p>
          {/* <p>Phone: +123-456-7890</p> */}
        </div>
        {/* <div className="footer-details">
          <p>Some other details can go here.</p>
        </div> */}
      </div>
      <div className="footer-image">
        <img src={exampleImage} />
      </div>
    </footer>
  );
};

export default Footer;
