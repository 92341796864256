// src/components/Body.js

import React from "react";
import "./Body.css";
import exampleImage from "../assets/123.png";

const Body = () => {
  return (
    <div className="body section__padding">
      <div className="body-image">
        <img src={exampleImage} alt="Example" />
      </div>
      <div className="body-content">
        <div>
          <h1>Exciting News</h1>
        </div>
        <div>
          <h2>Our new watch collection is almost here!</h2>
        </div>
        <p>
          Stay tuned for the launch of timeless elegance and unparalleled
          craftsmanship.
        </p>
        <h2>Coming Soon!</h2>
      </div>
    </div>
  );
};

export default Body;
